.featuresHeaderContainer {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    color: #fff;
}

.articlesContainer {
    display: flex;
    gap: 2rem;
}

.Features {
    padding-left: 7%;
    padding-right: 7%;
    padding-bottom: 4rem;
}

.FeaturesCardItem {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.081);
    border-radius: 10px;
    margin-top: 4rem;
    padding-left: 1rem;
    padding-right: 2.4rem;
    text-align: left;
    font-size:medium;
    padding-bottom: 2rem;
    color: #fff;
}

.featuresHeaderContainer > h3 {
    text-align: left;
}

.featuresLinkContainer > a {
    color: #fff;
}

@media(max-width: 1028px) {
    .articlesContainer {
        display: flex;
        flex-direction: column;
    }

    .FeaturesCardItem {
        width: auto;
    }
}