.Start {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: calc(100vh - 5rem);
    color: #fff;
}

.startHeaderContainer {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    align-items: center;
}

.startHeaderContainer > .Button {
    width: fit-content;
    margin-top: 2rem;
}

@media(max-width: 1028px) {
    .Start {
        font-size: smaller;
        padding-left: 7%;
        padding-right: 7%;
    }
}