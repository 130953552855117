.Ad {
    padding-left: 7%;
    padding-right: 7%;
    color: #fff;
    padding-bottom: 10rem;
    padding-top: 4rem;
}

.adContainer {
    border: solid 1px rgba(6, 196, 196, 0.533);
    border-radius: 20px;
    padding-left: 4rem;
    padding-right: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media(max-width: 1028px) {
    .adContainer {
        display: flex;
        flex-direction: column;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 2rem;
    }

}