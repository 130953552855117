.MarketTrend {
    color: #fff;
    padding-left: 7%;
    padding-right: 7%;
    padding-bottom: 10rem;
}

.MarketTrendCardItem {
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.081);
    width: 100%;
    border-radius: 10px;
    padding-left: 1rem;
    padding-right: 1rem;
}

.marketTrendsContainer {
    display: flex;
    justify-content: space-between;
    gap: 1.7rem;
}

.marketCardTopContainer {
    display: flex;
    gap: 1rem;
    border-bottom: solid 1px black;
    align-items: center;
    border-color: #fff;
}

.marketCardBottomContainer {
    display: flex;
}

.cryptoName {
    background-color: #fff;
    padding-left: 10px;
    padding-right: 10px;
    color: black;
    font-size: smaller;
    border-radius: 2.5px;
}

@media(max-width: 1028px) {
    .marketTrendsContainer {
        display: flex;
        flex-direction: column;
    }

    .MarketTrendCardItem {
        width: auto;
    }
}