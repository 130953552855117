.Footer {
    padding-left: 7%;
    padding-right: 7%;
    border-top: solid 2.7px rgba(255, 255, 255, 0.081);;
    display: flex;
    color: #fff;
    justify-content: space-between;
    padding-top: 2.7rem;
    padding-bottom: 2.7rem;
}

.footerLinks {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.footerLink:hover {
    cursor: pointer;
    opacity: 0.7;
}

.socialLinksContainer {
    display: flex;
    gap: 10px;
}

.socialLinksContainer ion-icon {
    font-size: 1.4rem;
}

.socialLinksContainer > a {
    color: #fff;
}

.socialLinksContainer > a:hover {
    opacity: 0.7;
}

@media(max-width: 1028px) {
    .Footer {
        display: flex;
        flex-direction: column;
    }
}