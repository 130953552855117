.GetStarted {
    padding-left: 7%;
    padding-right: 7%;
    color: #fff;
    padding-bottom: 7rem;
    display: flex;
    justify-content: space-between;
}

.getStartedCard {
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.081);
    padding-left: 2.4rem;
    padding-right: 10rem;
    font-size: smaller;
    gap: 2rem;
    border-radius: 14px;
    height: 100px;
}

.getStartedLogoContainer ion-icon {
    font-size: 4rem;
}

.getStartedRightContainer {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}


@media(max-width: 1028px) {
    .GetStarted {
        display: flex;
        flex-direction: column;
    }

    .getStartedCard {
        display: flex;
        padding-right: 1em;
        padding-top: 1.4rem;
        padding-bottom: 1.4rem;
    }

    .mobileDiv {
        width: 100%;
    }

    .getStartedButtonContainer {
        margin-bottom: 1rem;
    }
}